import React, { Component } from 'react';

class OurValues extends Component {
  render() {
    const sectionStyle = {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '50px 20px',
      backgroundColor: '#0e1130',
      color: '#fff',
      textAlign: 'center'
    };

    const headingStyle = {
      fontSize: '3rem',
      fontFamily: 'Georgia, serif',
      color: '#fff',
      marginBottom: '20px'
    };

    const subheadingStyle = {
      fontSize: '3rem',
      fontFamily: 'Georgia, serif',
      color: '#edc987'
    };

    const paragraphStyle = {
      fontSize: '1rem',
      color: '#ecf0f1',
      maxWidth: '800px'
    };

    return (
      <div style={sectionStyle}>
        <h1 style={headingStyle}>
          Our <span style={subheadingStyle}>values</span>
        </h1>
        <p style={paragraphStyle}>
          Our values are the foundation of the culture we have created. It is the way we approach business internally, as well as externally.
        </p>
      </div>
    );
  }
}

export default OurValues;
