import React, { useState, useEffect } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const ImageGallery = ({ publicUrl, imagealt, imglist = [],setShowNav }) => {
  const images = [
    publicUrl + "assets/img/news/图片1.png",
    publicUrl + "assets/img/news/图片2.png",
    publicUrl + "assets/img/news/图片3.png"
  ];
  const [index, setIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [showImgList, setShowImgList] = useState([]);
  useEffect(() => {
    setShowImgList(imglist.length > 0 ? imglist : images);
  }, [imglist, images]);


  return (
    <div className="property-details-slider">
      {showImgList.slice(0, 2).map((image, indexs) => (
        <div className="item" key={indexs}>
          <div className="thumb">
            {/* <img ref={containerHandler} src={image} alt={imagealt}  onClick={handleClick}/> */}
            <img
              src={image}
              onClick={() => {
                setIsOpen(true);
                setShowNav(false)
                setIndex(indexs);
              }}
            />
            
          </div>
        </div>
      ))}
      {isOpen && (
              <Lightbox
                mainSrc={showImgList[index]}
                // nextSrc={showImgList[(index + 1) % showImgList.length]}
                // prevSrc={showImgList[(index + showImgList.length - 1) % showImgList.length]}
                onCloseRequest={() => {setIsOpen(false);setShowNav(true)}}
                onMovePrevRequest={() => setIndex(index - 1)}
                onMoveNextRequest={() => setIndex(index + 1)}
              />
            )}
    </div>
  );
};

export default ImageGallery;
