import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';
import { Link } from 'react-router-dom';
class Team extends Component {


  render() {

    let publicUrl = process.env.PUBLIC_URL + '/'
    let data = sectiondata.team
    const titleStyle = {
      fontSize: '4rem', // Adjust as needed
      fontFamily: 'Georgia, serif',
      color: '#2c3e50', // Dark grey for the main text
      textAlign: 'center',
      marginBottom: '20px',
    };

    const highlightStyle = {
      color: '#edc987', // Orange for the highlighted text
    };

    return <div className="team-area bg-gray mg-top-70 pd-top-90 pd-bottom-70">
      <div className="container">
        <div className="section-title text-center">
          <h2 style={titleStyle}>Meet the <span style={highlightStyle}>Helen Realty</span> Team</h2>
          <h5 className="sub_title">Our team of Adelaide real estate experts can help achieve your property goals. Let's make it happen.</h5>
        </div>
        <div className="row">
          {data.items.map((item, i) =>
            <div key={i} className="col-lg-4 col-sm-6">
              <Link className="single-team" to={'/employee-detail?index=' + i}>
                <div className="thumb">
                  <img src={publicUrl + item.image} alt="team" />
                </div>
                <div className="team-details">
                  
                {/* <ul style={{marginTop:'-25px'}}>
                    {item.social.map((social, i) =>
                      <li key={i}><a href={social.url}><i className={social.icon} /></a></li>
                    )}
                  </ul> */}
                  <h4  className='text-center' style={{color:'var(--main-color-one)' }}>{item.name}</h4>

                  <div className='text-center' style={{color:'var(--paragraph-color)',marginBottom:'10px'}}>{item.designation}</div>

                 
                  <div className='view-profile'>
                   <Link className='link2-detail' style={{padding:'6px 18px'}} to={'/employee-detail?index=' + i}>View Profile</Link>
                  </div>
                  {/* <div className="team_description" style={{ height: '400px',color:'var(--paragraph-color)'}}>{item.description}</div> */}
                </div>
              </Link>
            </div>
          )}

        </div>
      </div>
    </div>

  }
}

export default Team