import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';
import parse from 'html-react-parser';

class AppertmentSlider extends Component {


  render() {

    let publicUrl = process.env.PUBLIC_URL + '/'
    let imagealt = 'image'
    let data = sectiondata.appertmentslider

    const sectionTitleStyle = {
      textAlign: 'left',

    };
    return <div className="apartments-area pd-top-100">
      <div className="container">
        <div className="section-title" style={sectionTitleStyle}>
          <h3 className="title">Recommended Projects</h3> {/* Correctly reference title */}
        </div>
        <div className="apartments-slider">
          {data.items.map((item, i) =>
            <div key={i} className="item">
              <div className="row justify-content-center">
                <div className="col-lg-8">
                  <div className="thumb" style={{ position: 'relative' }}>
                    {/* <h2 className="title">{item.title}</h2> */}
                    <img src={publicUrl + item.image} alt="img" style={{ display: 'block', width: '100%' }} />
                    <div style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      backgroundColor: 'rgba(255, 255, 255, 0.3)', // 白色半透明
                      pointerEvents: 'none' // 确保覆盖层不影响img的交互
                    }}></div>
                  </div>
                </div>
                <div className="col-lg-4 align-self-center">
                  <h2 className="title">{item.title}</h2>
                  <div className="details">
                    <h2 className="title">{item.title}</h2>
                    {/* <h4 className="mb-0"><del>{item.OlderPrice}</del></h4> */}
                    <h4 className="style={{ whiteSpace: 'nowrap' }}">{item.newPrice}</h4>
                    {item.itemlists.map((itemlist, i) =>
                      <div key={i}>
                        <h5 className="sub-title">{itemlist.title}</h5>
                        <span><i className={itemlist.icon} />{itemlist.subtitle}</span>
                      </div>
                    )}

                  </div>
                </div>
              </div>
            </div>
          )}

        </div>
      </div>
    </div>
  }
}

export default AppertmentSlider