import { useEffect, useState } from "react"

const Select = () => {
    const data = [
        {
            label: "SELL",
            Placeholder: "Start typing to find your address",
            title: "What's your property worth?",
            btnText: "Find Out"
        }, {
            label: "BUY",
            Placeholder: "Search by suburb...",
            title: "Let's find your next home",
            btnText: "Search"
        }, {
            label: "MANAGE",
            Placeholder: "Start typing to find your address",
            title: "Discover your investment’s potential",
            btnText: "Find Out"
        }, {
            label: "RENT",
            Placeholder: "Search by suburb...",
            title: "Looking for your next move?",
            btnText: "Search"
        }
    ]
    const [selected, setSelected] = useState(data[0])
    // const selectData = useEffect(()=>{
    //     return data.filter(item => item.label === selected)[0].title
    // },[selected])

    return (
        <>
            <div className="select-wrap">
                <div className="container">
                    <div className="select-title">
                        <h2 className="text-center" style={{marginBottom:'30px'}}>{selected.title}</h2>
                    </div>
                    <div className="search-form-tabs">
                        {data.map(item => {
                            return <a key={item.label} className={item.label === selected.label ? 'search-form-tabs-active' : ''} onClick={() => setSelected(item)}>{item.label}</a>
                        })}
                    </div>
                    <div className="input-wrap">
                        <input placeholder={selected.Placeholder}></input>
                        <button className="input-wrap-filters">Filters</button>
                        <button className="input-wrap-find">Find Out</button>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Select