import React, { Component } from 'react';

class Mission extends Component {
  // yuchen
    render() {
      const mission= {
        "items": [
          {
            "number": "01",
            "title": "Precision",
            "url": "#",
            "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi vitae venenatis mi"
          },
          {
            "number": "02",
            "title": "Passion",
            "url": "#",
            "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi vitae venenatis mi",
            "class": "offset-xl-1"
          },
          {
            "number": "03",
            "title": "Authenticity",
            "url": "#",
            "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi vitae venenatis mi",
            "class": "offset-xl-1"
          }
        ]
      }


    return <div className="mission-vission-area pd-bottom-50" style={{marginTop: '50px'}}>
            <div className="container">
              <div className="row justify-content-center">
                { mission.items.map( ( item, i )=>
                  <div key={ i } className="col-xl-3 col-lg-4 col-sm-6">
                      <div className="single-intro text-lg-left text-center">
                        <div className="text">
                          { item.number }
                        </div>
                        <div className="details">
                          <h4 className="title"><a href="#">{ item.title }</a></h4>
                          <p>{ item.content }</p>
                        </div>
                      </div>
                    </div>
                 ) }
                

              </div>
            </div>
          </div>
          {/* choose us start */}

        }
}

export default Mission
