import React, { Component } from 'react';
import './i18n';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, HashRouter, Route, Switch } from "react-router-dom";
import blogdata from './data/blogdata.json';
import Singleblogdata from './data/single-blogdata.json';
import HomeV1 from './components/home-v1';
import HomeV2 from './components/home-v2';
import HomeV3 from './components/home-v3';
import HomeV4 from './components/home-v4';
import Property from './components/property';
import AvilableProperty from './components/availavbe-property';
import PropertiesByCity from './components/properties-by-city';
import RecentProperties from './components/recent-properties';
import PropertyDetails from './components/property-details';
import About from './components/about';
import Advisor from './components/advisor';
import Pricing from './components/pricing';
import UserList from './components/user-list';
import Registraion from './components/registration';
import Error from './components/error';
import Faq from './components/faq';
import News from './components/news';
import NewsDetails from './components/news-details';
import Contact from './components/contact';
import SearchMap from './components/search-map';
import SearchGrid from './components/search-grid';
import SearchList from './components/search-list';
import RentList from './components/rent-list';
import AddNew from './components/add-property';
import EmployeeDetails from './components/employee_details';

// yuchen test import
import AboutTeam from './components/about_team';
import AboutUs from './components/about_us';
import Career from './components/career';
import HelenAdmin from './components/helen_admin';
import AdminLogin from './components/admin_login';

import GetAppraisal from './components/get-appraisal'
import Sellwith from './components/sell-with'
import Properties4sale from './components/properties4sale'

import PropertyDetailsRent from './components/property-details-rent';

import Buywith from './components/buy-with'
// Eugenia import
import NewsDetails1 from './components/news-details1'
import NewsDetails2 from './components/news-details2'
import NewsDetails3 from './components/news-details3'
import Disclaimer from './components/disclaimer';


import ReportPreview from './components/property-report-preview';

class Root extends Component {
    render() {
        return (
            <Router>
                <HashRouter basename="/">
                    <div>
                        <Switch>
                            <Route exact path="/" component={HomeV2} />
                            {/* <Route path="/home-v2" component={HomeV2} /> */}
                            <Route path="/home-v3" component={HomeV3} />
                            <Route path="/project" component={HomeV4} />
                            <Route path="/property" component={Property} />
                            <Route path="/availavbe-property" component={AvilableProperty} />
                            <Route path="/properties-by-city" component={PropertiesByCity} />
                            <Route path="/property-for-sale" component={RecentProperties} />
                            <Route path="/property-details" component={PropertyDetails} />
                            <Route path="/property-details-rent" component={PropertyDetailsRent} />
                            <Route path="/about" component={About} />
                            <Route path="/advisor" component={Advisor} />
                            <Route path="/pricing" component={Pricing} />
                            <Route path="/user-list" component={UserList} />
                            <Route path="/registration" component={Registraion} />
                            <Route path="/error" component={Error} />
                            <Route path="/faq" component={Faq} />
                            <Route path="/news" component={News} />
                            <Route path="/news-details" component={NewsDetails} />
                            <Route path="/contact" component={Contact} />
                            <Route path="/search-map" component={SearchMap} />
                            <Route path="/search-grid" component={SearchGrid} />
                            <Route path="/property-for-rent" component={SearchList} />
                            <Route path="/add-property" component={AddNew} />

                            {/* yuchen test */}
                            <Route path="/aboutteam" component={AboutTeam} />
                            <Route path="/employee-detail" component={EmployeeDetails} />
                            <Route path="/aboutus" component={AboutUs} />
                            <Route path="/career" component={Career} />
                            <Route path="/helendata" component={HelenAdmin} />
                            <Route path="/helenlogin" component={AdminLogin} />


                            {/* Eugenia test */}
                            <Route path="/getappraisal" component={GetAppraisal} />
                            <Route path="/sell-with" component={Sellwith} />
                            <Route path="/news-details1" component={NewsDetails1} />
                            <Route path="/news-details2" component={NewsDetails2} />
                            <Route path="/news-details3" component={NewsDetails3} />
                            <Route path="/disclaimer" component={Disclaimer} />

                            {/* Patrick test */}

                            {/* <Route path="/properties4sale" component={Properties4sale} /> */}

                            {/* William test */}
                            <Route path="/buy-with" component={Buywith} />
                            <Route path="/rent-list" component={RentList} />
                            <Route path="/property-report-preview" component={ReportPreview} />

                            
                        </Switch>
                    </div>
                </HashRouter>
            </Router>
        )
    }
}

export default Root;

ReactDOM.render(<Root />, document.getElementById('realdeal'));
