import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import Mission from './section-components/mission-two';
import Professional1 from './section-components/professional1';
import SellHome from './section-components/sellhome';
import Client from './section-components/client';
import BuySellTable from './section-components/buy-sell-table';
import Footer from './global-components/footer';

const Sellwith = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="Sell with Helen Realty" />
        <br />
        <br />
        <Professional1 />
        <SellHome />
        <Mission />
        <Client />
        {/* <Process /> */}
        {/* <BuyOrSell /> */}
        <BuySellTable />
        <br />
        <Footer />
    </div>
}

export default Sellwith

