import React, { useState, useEffect } from 'react';
import OwnerInfo from './OwnerInfo';
import ImageGallery from './ImageGallery.js';
import { getSavedAddressDetails } from './addressService'; // 导入 getSavedAddressDetails
import sectiondata from '../../data/sections.json'; // 导入 sectiondata
import { useLocation } from 'react-router-dom';
import { getListing } from '../../data/api';
import emailjs from '@emailjs/browser';

const PropertyDetails = ({ getHeaderTitle,setShowNav }) => {
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [addressDetails, setAddressDetails] = useState(null);
  const location = useLocation();
  const [formData,setFormData] = useState({
    name:'',
    email:"",
    message:''
  })
  const queryParams = new URLSearchParams(location.search);
  const uniqueID = queryParams.get('uniqueID');
  const type = queryParams.get('type') || 'sell';
  // const [currentDetail, setCurrentDetail] = useState({})
  const googleMapApiKey = 'AIzaSyCW7hPnNqKXjZZOZ2mpPoR7xlTKpL4LDIY'

  function formatAddress(address) {
    const {
        streetNumber,
        street,
        suburb,
        state,
        postcode,
        country
    } = address;

    return `${streetNumber} ${street}, ${suburb}, ${state} ${postcode} ${country}`;
}


  useEffect(() => {
    const savedAddressDetails = getSavedAddressDetails();
    setAddressDetails(savedAddressDetails.length > 0 ? savedAddressDetails[0] : sectiondata.searchlist.items[0]);
    getListing().then((res) => {
      console.log('res', res);
      // filter all the latest item in the array from @attributes->date
      // sort the array by date, the date format is '2024-08-23-15:16:04'
      res.sort((a, b) => {

        // 将时间字符串中的 "-" 替换为 " "，以便构造新的 Date 对象
        const formattedTimeStringA = a['@attributes'].date.replace(/-/g, " ").replace(" ", "T").replace("T", " ") + "Z";

        // 创建 Date 对象
        const dateObjectA = new Date(formattedTimeStringA);

        // 获取时间戳（毫秒）
        const timestampA = dateObjectA.getTime();


        // 将时间字符串中的 "-" 替换为 " "，以便构造新的 Date 对象
        const formattedTimeStringB = b['@attributes'].date.replace(/-/g, " ").replace(" ", "T").replace("T", " ") + "Z";

        // 创建 Date 对象
        const dateObjectB = new Date(formattedTimeStringB);

        // 获取时间戳（毫秒）
        const timestampB = dateObjectB.getTime();
        return timestampB - timestampA;

        // return new Date(b['@attributes'].date) - new Date(a['@attributes'].date);
      });


      // get the first 3 items
      let latestItems = res.slice(0, 7);

      console.log('latestItems', latestItems);


      // add all the items in each object's residential array to a new array
      let residentialItems = [];

      latestItems.forEach((item) => {
        if (type === 'rent') {
          if (item.rental) {
            residentialItems = residentialItems.concat(item.rental);
          }
        } else {
          if (item.residential) {
            residentialItems = residentialItems.concat(item.residential);
          }
        }
      });

      console.log('residentialItems', residentialItems);

      // filter all the unique residential items by uniqueID in the array
      let uniqueResidentialItems = residentialItems.filter((item, index, self) =>
        index === self.findIndex((t) => (
          t.uniqueID === item.uniqueID
        ))
      );
      console.log('uniqueResidentialItems', uniqueResidentialItems);
      const currentDetail = uniqueResidentialItems.filter(item => item.uniqueID === uniqueID)[0]
      // uniqueResidentialItems.forEach(item=>item.url = `/property-details?uniqueID=${item.uniqueID}`)
      // console.log(currentDetail, 'currentDetail');
      currentDetail.listingAgent = currentDetail.listingAgent.filter(item => item.name)
      currentDetail.listingAgent.forEach(item => {
        if (item.name) {
          const name = item.name.split(' ');
          item.url = `${publicUrl}assets/img/team/${name.join('_')}.png`;
          item.name2 = name[0]
        } else {
          return
        }
      })

      const map = new Map();
      currentDetail.listingAgent = currentDetail.listingAgent.filter(v => !map.has(v.id) && map.set(v.id, v));

      // [1, 2, 4, null, "3", "abc", 3, 5]

      // setCurrentDetail(currentDetail);
      setAddressDetails((cur) => {
        const description = currentDetail?.description.length > 0 ? currentDetail?.description?.split(/\n\s*\n/) : '';
        // console.log(description, 'description');
        const imglist = currentDetail.objects.img.map(item => {
          if (item['@attributes'].url) {
            return item['@attributes'].url
          } else {
            return null
          }
        }).filter(item => item !== null)
        
        return {
          title: currentDetail.address.street + " " + currentDetail.address.suburb,
          features: currentDetail.features,
          shortDescription: description[0],
          fullDescriptionParagraphs: description.slice(1),
          listingAgent: currentDetail.listingAgent || [],
          floorplan: currentDetail.objects.floorplan,
          imglist: imglist,
          addressStr:formatAddress(currentDetail.address)
        }
      })
      getHeaderTitle(currentDetail.address.street + " " + currentDetail.address.suburb)
      console.log(addressDetails, 'addressDetails');

    });
  }, []);



  const handleToggleDescription = () => setShowFullDescription(!showFullDescription);

  let publicUrl = process.env.PUBLIC_URL + '/';
  let imagealt = 'image';


  const shortDescription = addressDetails
    ? addressDetails.shortDescription || `Welcome to your dream home! This luxurious double-storey villa boasts 4 bedrooms, 3 bathrooms, 4 toilets, and 2 living rooms, with a double garage. The house is fully furnished with high-end, modern amenities, designed for maximum comfort and convenience. This house is perfect for families looking to live with their parents.`
    : `Welcome to your dream home! This luxurious double-storey villa boasts 4 bedrooms, 3 bathrooms, 4 toilets, and 2 living rooms, with a double garage. The house is fully furnished with high-end, modern amenities, designed for maximum comfort and convenience. This house is perfect for families looking to live with their parents.`;

  const fullDescriptionParagraphs = addressDetails?.fullDescriptionParagraphs
    ? addressDetails.fullDescriptionParagraphs
    : [
      `The location of this property is second to none, with a peaceful and serene environment that is close to everything you need. The community is surrounded by natural beauty, including numerous parks and conservation areas, and is adjacent to the Park Holme library. You'll find various entertainment venues, popular fitness centers, and some of the most beautiful beaches in the area, such as Glenelg and Henley beach, just a stone's throw away.`,
      `For your convenience, there are many shopping centers nearby, including the Park Holme shopping center, Castle Plaza, and the Westfield Marion shopping center, offering an abundance of options for you to enjoy your life to the fullest.`,
      `Not to mention, the area is home to some of the best schools in the region, including the prestigious Westminster School, providing excellent education options for your children.`,
      `This property is truly a gem, with everything you need to enjoy life to the fullest. Don't miss out on the opportunity to make it your own! Contact us to schedule a viewing and take the first step towards your dream home!`,
      `Features you will love include:`,
      `• Torrens title`,
      `• Two color schemes are available for the interior decoration design.`,
      `• High-end kitchen equipped with ROBAM range hood, cooktop and steam oven, and optional BOSCH or SMEG oven and dishwasher`,
      `• Wooden flooring combined with tiled flooring throughout the house for easy cleaning and maintenance`,
      `***Please note that the images provided above are for display purposes only and are intended to serve as a reference for selecting interior design styles for your property***`
    ];

  const descriptionStyle = {
    maxHeight: showFullDescription ? '1000px' : '100px',
    overflow: 'hidden',
    transition: 'max-height 0.5s ease'
  };


  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
};

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log('Submitting form data:', formData);
    try {
        // const response = await axios.post('/api/submit-property-appraisal', formData);
        emailjs
        .sendForm(
            'service_35t22ol',
            'template_nhlam6w',
            event.target,
            {
                publicKey: 'LZ-kPMdGkeWK36pmZ',
            }
        )
        .then((result) => {
            console.log(result.text);
            if (result.text === "OK") {
                setFormData(prevState => {
                    const clearedState = {};
                    Object.keys(prevState).forEach(key => {
                      clearedState[key] = ''; // 或者 undefined
                    });
                    alert('Form submitted successfully!');
                    return clearedState;
                  });
            }
        }
        );
    } catch (error) {
        console.error('Error submitting form:', error);
        alert('Error submitting the form.');
    }
};



  return (
    <div className="property-details-area">
      <div className="bg-gray pd-top-100 pd-bottom-90">
        <div className="container" style={{minWidth:"1500px"}}>
          <div className="row">
            <div className="col-xl-9 col-lg-8">
              <div style={{ display: 'flex', gap: '20px' }}>
                <div className="property-details-slider-info" style={{ width: '30%',display:"flex",flexDirection:"column",alignContent:"center" }}>
                  <div>
                    <h5 style={{ backgroundColor: 'navy', color: 'white', display: 'inline-block', padding: '5px', marginRight: '10px' }}>
                      <span>{type === 'rent' ? 'For Rent' : 'For Sale'}</span>
                    </h5>
                    <h5 style={{ display: 'inline-block' }}>
                      <span>Contact Agent</span>
                    </h5>
                    <h5>{addressDetails ? addressDetails.title : '4 Davidson Avenue, Park Holme SA 5043, Australia'}</h5>
                    <div className="row">
                      <div className="col-md-3 col-sm-6">
                        <div className="single-property-info">
                          {/* <h5>Bedrooms</h5> */}
                          <p><i className="fa fa-bed" />{addressDetails ? addressDetails.features.bedrooms : '4'}</p>
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-6">
                        <div className="single-property-info">
                          {/* <h5>Bathrooms</h5> */}
                          <p><i className="fa fa-bath" />{addressDetails ? addressDetails.features.bathrooms : '2'}</p>
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-6">
                        <div className="single-property-info">
                          {/* <h5>Parking</h5> */}
                          <p><i className="fa fa-car" />{addressDetails ? addressDetails.features.garages : '01 Indoor'}</p>
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-6">
                        <div className="single-property-info">
                          {/* <h5>Area</h5> */}
                          <p><img src={publicUrl + "assets/img/icons/7.png"} alt={imagealt} />{addressDetails ? addressDetails.area : '1,038 sq'}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{marginTop:"20px",color:'#000',fontWeight:"600"}}>Best Offer by Tuesday 10th September at 12pm(USP)</div>
                  <div style={{marginTop:"20px"}}>Inspection tomorrow 11:30 am <span style={{color:"navy",fontWeight:"600"}}>View all</span></div>
                  {/* <div className="property-info mb-5">
                
                  </div> */}
                </div>
                <div style={{ overflow: 'hidden', width: 'calc(70% - 20px)', marginRight: '-20px' }}>
                  <div style={{ overflow:'hidden' }}>
                    <ImageGallery publicUrl={publicUrl} imagealt={imagealt} imglist={addressDetails?.imglist} setShowNav={setShowNav} />
                  </div>
                  <div style={{width:'100%',display:"flex",marginTop:'-36px',justifyContent:'space-between'}}>
                    {
                      addressDetails?.imglist && addressDetails?.imglist.slice(0,3).map(items=>{
                        return (
                          <div key={items} style={{marginRight:'6px'}}><img src={items} style={{flex:'33%'}}></img></div>
                        )
                      })
                    }
                  </div>
                </div>

              </div>


              <div className="property-news-single-card style-two border-bottom-yellow">
                <h4>Property Description</h4>
                <div style={descriptionStyle}>
                  <p>{shortDescription}</p>
                  <p>{fullDescriptionParagraphs}</p>
                  {/* {showFullDescription && fullDescriptionParagraphs.map((paragraph, index) => (
                    <p key={index}>{paragraph}</p>
                  ))} */}
                </div>
                <a onClick={handleToggleDescription} style={{ cursor: 'pointer', color: '#006400', textDecoration: 'underline' }}>
                  {showFullDescription ? 'Read Less' : 'Read More'}
                </a>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4">
              <div className="widget widget-owner-info mt-lg-0 mt-5">
                {
                  addressDetails?.listingAgent && addressDetails?.listingAgent.map(item => {
                    return <OwnerInfo key={item.url} imgSrc={item.url} imgAlt={item.name2} name={item.name} title='Sales Manager' />
                  })
                }
                <div className="contact">
                  <h6>Contact Us</h6>
                  <form onSubmit={handleSubmit}>
                  <div className="rld-single-input">
                    <input type="text"  name="name" placeholder="Full Name" value={formData.name} onChange={handleChange} />
                  </div>
                  <div className="rld-single-input">
                    <input type="email"  name="email" placeholder="Email" value={formData.email} onChange={handleChange} />
                  </div>
                  <div className="rld-single-input">
                    <input type="text" name="message" placeholder="Messages" value={formData.message} onChange={handleChange} />
                  </div>
                  <button className="btn btn-yellow" type="submit">Send Messages</button>
                  </form>

                </div>
                <div className="contact-info">
                  <h6 className="mb-3">Contact Info</h6>
                  <div className="media">
                    <div className="media-left">
                      <img src={publicUrl + "assets/img/icons/1.png"} alt={imagealt} />
                    </div>
                    <div className="media-body">
                      <p>Address</p>
                      <span>{addressDetails ? addressDetails.title : '4 Davidson Avenue, Park Holme SA 5043, Australia'}</span>
                    </div>
                  </div>
                  <div className="media">
                    <div className="media-left">
                      <i className="fa fa-phone" />
                    </div>
                    <div className="media-body">
                      <p>Phone</p>
                      <span style={{ display: 'block' }}>+61 430 888 866 Helen</span>
                      <span style={{ display: 'block' }}>+61 401 364 784 Vivian</span>
                    </div>
                  </div>
                  <div className="media mb-0">
                    <div className="media-left">
                      <i className="fa fa-envelope" />
                    </div>
                    <div className="media-body">
                      <p>Email</p>
                      <span style={{ display: 'block' }}>helen@helenrealtysa.com</span>
                      <span style={{ display: 'block' }}>vivian.zhang@helenrealtysa.com</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row pd-top-90">
          <div className="col-lg-9">

            <div className="property-news-single-card style-two border-bottom-yellow">
              <h4>Address</h4>
              {addressDetails?.addressStr&&<iframe
                src={`https://www.google.com/maps/embed/v1/place?key=${googleMapApiKey}&q=${encodeURIComponent(addressDetails.addressStr)}`}
                width="600"
                height="450"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade">
              </iframe>}
            </div>
            <div className="property-news-single-card border-bottom-yellow">
              <h4>Floor Plan</h4>
              <div className="thumb">
                {
                  addressDetails?.floorplan && addressDetails?.floorplan.map(item => {
                    return item['@attributes']?.url && <img key={item['@attributes'].url} src={item['@attributes'].url} alt={imagealt} />
                  })
                }
                {/* <img src={publicUrl + "assets/img/others/图片1.png"} alt={imagealt} /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PropertyDetails;
