import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import sectiondata from '../../data/sections.json';

const Matrix = ({ publicUrl = process.env.PUBLIC_URL + '/', imagealt = 'image' }) => {
  const [clickedIndex, setClickedIndex] = useState(null);
  let data1 = sectiondata.explore;

  const data = {
    title: data1.title || 'Your Title Here',
    slogen: data1.slogen || 'Your Slogan Here',
    items: [
      { image: 'assets/img/banner/首页.png', url: '/property-for-sale', title: 'Buy', price: 'View properties for sale', content: '' },
      { image: 'assets/img/banner/首页2.png', url: '/property-for-rent', title: 'Rent', price: 'View properties for rent', content: '' },
      { image: 'assets/img/banner/frontpage.png', url: '/getappraisal', title: 'Sell', price: 'Sell with us', content: '' },
      { image: 'assets/img/banner/manage.avif', url: '/aboutus', title: 'Manage', price: 'Let us manage your property', content: '' },
      { image: 'assets/img/banner/projects.avif', url: '/project', title: 'Projects', price: 'Learn more', content: '' }
    ]
  };

  const gridContainerStyle = {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gridTemplateRows: 'repeat(2, 1fr)',
    gap: '2px',
    width: '90vw',
    height: '80vh',
    padding: '10px',
    margin: '0 auto'
  };

  const gridItemStyle = {
    backgroundColor: '#fff',
    borderRadius: '8px',
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    textAlign: 'center',
    textShadow: '0 0 5px rgba(0,0,0,0.5)',
    transform: 'scale(0.95)',
    textDecoration: 'none'
  };

  const itemStyles = [
    { gridColumn: 'span 2', gridRow: 'span 1' },
    { gridColumn: 'span 2', gridRow: 'span 1' },
    { gridColumn: 'span 1', gridRow: 'span 1' },
    { gridColumn: 'span 2', gridRow: 'span 1' },
    { gridColumn: 'span 1', gridRow: 'span 1' }
  ];

  const overlayStyle = {
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    background: 'rgba(0, 0, 0, 0.3)'
  };

  const thumbStyle = {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    filter: 'brightness(0.7)',
    transition: 'filter 0.3s ease'
  };

  const hoverThumbStyle = {
    filter: 'brightness(1)'
  };

  const clickedThumbStyle = {
    filter: 'sepia(1) saturate(1000%) hue-rotate(-20deg) brightness(1.2)' // Filter to achieve #edc987 color
  };

  const detailsStyle = {
    position: 'absolute',
    zIndex: 1,
    color: 'white'
  };

  const priceStyle = {
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    color: 'black',
    padding: '5px',
    borderRadius: '5px',
    display: 'inline-block',
    marginTop: '10px',
    textShadow: 'none'
  };

  const sectionTitleStyle = {
    textAlign: 'left',
    marginLeft: '10%',
  };

  return (
    <div>
      <div style={gridContainerStyle}>
        {data.items.map((item, index) => (
          <Link
            key={index}
            to={item.url}
            style={{ ...gridItemStyle, ...itemStyles[index] }}
            onMouseEnter={(e) => {
              e.currentTarget.querySelector('img').style.filter = hoverThumbStyle.filter;
            }}
            onMouseLeave={(e) => {
              e.currentTarget.querySelector('img').style.filter = index === clickedIndex ? clickedThumbStyle.filter : thumbStyle.filter;
            }}
            onClick={() => setClickedIndex(index)}
          >
            <div style={overlayStyle}></div>
            <img
              src={publicUrl + item.image}
              alt={imagealt}
              style={index === clickedIndex ? { ...thumbStyle, ...clickedThumbStyle } : thumbStyle}
            />
            <div style={detailsStyle}>
              <h3 style={{ color: '#edc987' }}>{item.title}</h3>
              <h4 style={priceStyle}>{item.price}</h4>
              <span>{item.content}</span>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Matrix;
