import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';

class Mission extends Component {
  // yuchen
  render() {
    let data = sectiondata.mission


    return <div className="mission-vission-area pd-bottom-70">
      <div className="container">
        {/* <div className='mission_title' style={{textAlign: 'center'}}><h1>We <span style={{color:"orange"}}>Specialise</span> in</h1></div> */}
        <div className='mission_title' style={{ textAlign: 'center', paddingTop: 50 }}><h1>Our <span style={{ color: "#edc987" }}>Values</span></h1></div>
        <div className="row justify-content-center">
          {data.items.map((item, i) =>
            <div key={i} className="col-xl-3 col-lg-4 col-sm-6">
              <div className="single-intro text-lg-left text-center">
                <div className="text">
                  {item.number}
                </div>
                <div className="details">
                  <h4 className="title"><a href="#">{item.title}</a></h4>
                  <p>{item.content}</p>
                </div>
              </div>
            </div>
          )}


        </div>
      </div>
    </div>
    {/* choose us start */ }

  }
}

export default Mission
