import React from 'react';
import Navbar from './global-components/navbar';
import Bannerv2 from './section-components/banner-v2';
import Explore from './section-components/explore';
import FeaturedProperties from './section-components/featured-properties';
import Ads from './section-components/ads';
import PropertiesByCities from './section-components/properties-by-cities';
import RecentProperties from './section-components/recent-properties';
import FeaturedPorject from './section-components/featured-project';
import WhyChooseUs from './section-components/why-choose-us';
import OurPartner from './section-components/our-partner';
import Footer from './global-components/footer';
import Matrix from './section-components/matrix';
import PostListnew from './blog-components/post-listnew';
import Mission from './section-components/mission-two';
import Testimonials from './section-components/Testimonials';
import PropertySection from './section-components/recent-properties-for-sold';

import AppertmentSlider from './section-components/appertment-slider';
import Select from './blog-components/select';
import Activity from './blog-components/activity';

const Home_V1 = () => {
    return <div>
        <Navbar />
        <div className='home-bg-wrap'></div>
        <Bannerv2 />
        {/* <PropertySection /> */}
        <Select></Select>
        <Activity></Activity>
        <PostListnew/>
        {/* <FeaturedProperties /> */}
        <Matrix/>
        {/* <Ads /> */}
        {/* <PropertiesByCities /> */}
        <AppertmentSlider />
        <Testimonials />
        {/* <FeaturedPorject /> */}
        {/* <WhyChooseUs /> */}
        {/* <OurPartner /> */}
        {/* <ca/> */}
        <Footer />
    </div>
}

export default Home_V1

