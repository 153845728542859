import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';
import { Link } from 'react-router-dom';
import { getListing } from '../../data/api';

class Property extends Component {

  // create a state to store the data
  constructor(props) {
    super(props);
    this.state = {
      residentialItems: [],
    };
  }




  // Write a function to call an API to fetch data
  componentDidMount() {
    getListing().then((res) => {
      console.log('res', res);
      // filter all the latest item in the array from @attributes->date
      // sort the array by date, the date format is '2024-08-23-15:16:04'
      res.sort((a, b) => {

        // 将时间字符串中的 "-" 替换为 " "，以便构造新的 Date 对象
        const formattedTimeStringA = a['@attributes'].date.replace(/-/g, " ").replace(" ", "T").replace("T", " ") + "Z";

        // 创建 Date 对象
        const dateObjectA = new Date(formattedTimeStringA);

        // 获取时间戳（毫秒）
        const timestampA = dateObjectA.getTime();


        // 将时间字符串中的 "-" 替换为 " "，以便构造新的 Date 对象
        const formattedTimeStringB = b['@attributes'].date.replace(/-/g, " ").replace(" ", "T").replace("T", " ") + "Z";

        // 创建 Date 对象
        const dateObjectB = new Date(formattedTimeStringB);

        // 获取时间戳（毫秒）
        const timestampB = dateObjectB.getTime();
        return timestampB - timestampA;

        // return new Date(b['@attributes'].date) - new Date(a['@attributes'].date);
      });


      // get the first 3 items
      let latestItems = res.slice(0, 7);

      console.log('latestItems', latestItems);

    
      // add all the items in each object's residential array to a new array
      let residentialItems = [];
      latestItems.forEach((item) => {
        if (item.residential) {
          residentialItems = residentialItems.concat(item.residential);
        }

      });
      console.log('residentialItems', residentialItems);

      // filter all the unique residential items by uniqueID in the array
      let uniqueResidentialItems = residentialItems.filter((item, index, self) =>
        index === self.findIndex((t) => (
          t.uniqueID === item.uniqueID
        ))
      );

      uniqueResidentialItems.forEach(item=>{
        item.url = `/property-details?uniqueID=${item.uniqueID}`;
        item.listingAgent.forEach(i=>{
          if(i.name){
            const name = i.name.split(' ');
            let publicUrl = process.env.PUBLIC_URL + '/';
            i.url = `${publicUrl}assets/img/team/${name.join('_')}.png`;
            i.name2 = name[0]
        }else{
          return
        }
        })
      })
      console.log('uniqueResidentialItems', uniqueResidentialItems);

      this.setState({ residentialItems: uniqueResidentialItems });

    });

  }



  render() {
    let publicUrl = process.env.PUBLIC_URL + '/';
    let imagealt = 'image';
    let data = sectiondata.propertybtcities;
    const sectionTitleStyle = {
      textAlign: 'left',
    };

    return (
      <div className="properties-area pd-top-92">
        <div className="container">
          <div className="section-title" style={sectionTitleStyle}>
            <h3 className="title">Properties For Sale</h3>
          </div>
          <div className="row">
            {this.state.residentialItems.map((item, i) => (
              <div key={i} className="col-lg-4 col-sm-6 d-flex">
                <div className="single-feature d-flex flex-column" style={{ flex: 1 }}>
                  <div className="thumb" style={{ position: 'relative' }}>
                    <img
                      src={item.objects.img[0]['@attributes'].url}
                      alt="img"
                      style={{
                        height: '330px',
                        width: 'auto',  // 保持宽度自动，根据图片内容调整
                        objectFit: 'cover',  // 保持比例并裁剪溢出的部分
                        objectPosition: 'center center'  // 居中裁剪
                      }}
                    />
                    <div
                      style={{
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        width: '120px',
                        height: '60px',
                        backgroundColor: '#0e1130',
                        boxSizing: 'border-box',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <span
                        style={{
                          color: 'rgb(237, 201, 135)',
                          fontSize: '18px',
                          textAlign: 'center',
                        }}
                      >
                        For Sale
                      </span>
                    </div>
                  </div>
                  <div className="details d-flex flex-column flex-grow-1">
                    <h5 className="price">
                      {new Intl.NumberFormat('en-AU', { style: 'currency', currency: 'AUD' }).format(item.price)}
                    </h5>
                    <h4 className="title readeal-top">
                      <Link to={item.url}>{item.address.street + " " + item.address.suburb}</Link>
                    </h4>
                    <ul
                      className="info-list"
                      style={{
                        listStyleType: 'none',
                        padding: 0,
                        display: 'flex',
                        justifyContent: 'space-around',
                        width: '100%',
                        paddingTop: 15,
                      }}
                    >
                      <li>
                        <a className="bed" href="#">
                          <i className="fa fa-bed" />
                        </a>
                        {item.features.bedrooms}

                      </li>
                      <li>
                        <a className="bed" href="#">
                          <i className="fa fa-bath" />
                        </a>
                        {item.features.bathrooms}

                      </li>
                      <li>
                        <a className="bed" href="#">
                          <i className="fa fa-car" />
                        </a>
                        {item.features.garages}

                      </li>
                      {/* <li
                        key={i}
                        style={{
                          fontSize: 16,
                          textAlign: 'center',
                          marginBottom: '2px',
                          flex: 1,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center', // Ensure vertical alignment
                        }}
                      >
                        <div style={{ marginRight: '5px' }}>{features.item}</div>
                        <div>{features.title}</div>
                      </li> */}
                      {/* {item.features.map((features, i) => (
                        <li
                          key={i}
                          style={{
                            fontSize: 16,
                            textAlign: 'center',
                            marginBottom: '2px',
                            flex: 1,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center', // Ensure vertical alignment
                          }}
                        >
                          <div style={{ marginRight: '5px' }}>{features.item}</div>
                          <div>{features.title}</div>
                        </li>
                      ))} */}
                    </ul>
                    <div className="mt-auto">
                      <ul className="info-list">
                        <li>
                          <a className="sales" href="#">
                            <img
                              src={item.listingAgent[0].url}
                              alt="Sales Icon"
                              style={{
                                borderRadius: '50%', // 圆形
                                width: '50px', // 图片宽度
                                height: '50px', // 图片高度
                                objectFit: 'cover' // 保持图片比例
                              }}
                            />
                          </a>
                        </li>
                        {/* <li>
                          <a className="phone" href="#">
                            <i className="fa fa-phone" />
                          </a>
                        </li>
                        <li>
                          <a className="message" href="#">
                            <i className="fa fa-envelope" />
                          </a>
                        </li> */}
                        <li className="readeal-top">
                          <Link className="btn btn-yellow" to={item.url}>
                            View Details
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default Property;
