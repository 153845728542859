// BannerV3.js
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Autosuggest from 'react-autosuggest';
import sectiondata from '../../data/sections.json';
import { saveAddressDetails } from './addressService'; // 导入 saveAddressDetails
import axios from 'axios';
// import test from './test.json';
// CoreLogic API Credentials
const corelogic_token_url = "https://api-sbox.corelogic.asia/access/as/token.oauth2";
const corelogic_address_suggestion_url = "https://api-uat.corelogic.asia/sandbox/property/au/v2/suggest.json";
const corelogic_address_details_url = "https://api-sbox.corelogic.asia/property-details/au/properties";
const GOOGLE_API_KEY = 'AIzaSyCW7hPnNqKXjZZOZ2mpPoR7xlTKpL4LDIY'
const corelogic_data = {
  grant_type: "client_credentials",
  client_id: "SsWXjmKs7HVwMNTEAPbbAYr3IK0SGti7",
  client_secret: "AEk2drAEMozmdP2k"
};
const corelogic_data_formBody = Object.keys(corelogic_data)
  .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(corelogic_data[key]))
  .join('&');

async function Get_Corelogic_Token(url, formBody) {
  let response = await fetch(url, {
    method: 'POST',
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: formBody,
  });
  response = await response.json();
  return response;
}

async function Get_Address_Suggestions(url, token) {
  let response = await fetch(url, {
    method: 'GET',
    headers: {
      "Accept": "application/json",
      "Authorization": "Bearer " + token,
    },
  });
  response = await response.json();
  return response;
}

async function Get_Address_Details(url, token) {
  let response = await fetch(url, {
    method: 'GET',
    headers: {
      "Accept": "application/json",
      "Authorization": "Bearer " + token,
    },
  });
  response = await response.json();
  return response;
}

const styles = `
  .autosuggest-container {
    position: relative;
  }
  .autosuggest-suggestions-container {
    position: absolute;
    z-index: 2;
    border: 1px solid #ccc;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
  }
  .autosuggest-suggestions-list {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .autosuggest-suggestion {
    padding: 10px;
    cursor: pointer;
  }
  .autosuggest-suggestion--highlighted {
    background-color: #ddd;
  }
  .left-icon {
    position: relative;
  }
  .left-icon input {
    padding-left: 30px;
  }
  .left-icon::before {
    content: '\\f002';
    font-family: FontAwesome;
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #ccc;
  }
`;

class BannerV3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: '',
      suggestions: [],
      accessToken: '',
      addressDetails: null
    };
  }

  componentDidMount() {
    this.getAccessToken();
  }

  getAccessToken = async () => {
    try {
      const response = await Get_Corelogic_Token(corelogic_token_url, corelogic_data_formBody);
      console.log('Access token response:', response);
      this.setState({ accessToken: response.access_token }, () => {
        console.log('Access token set in state:', this.state.accessToken);
      });
    } catch (error) {
      console.error('Error fetching access token:', error);
    }
  };

  onChange = (event, { newValue }) => {
    this.setState({
      address: newValue,
    });
  };

  getSuggestions = async (value) => {
    const input = encodeURIComponent(value);
    const url = `https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${input}&key=${GOOGLE_API_KEY}&components=country:au`;
  
    try {
      const response = await axios.get(url);
      return response.data.predictions;
    } catch (error) {
      console.error('Error fetching suggestions:', error);
      return [];
    }
  };

  onSuggestionsFetchRequested = async ({ value }) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    if (inputLength < 3) {
      console.warn('Too few characters for suggestion');
      this.setState({ suggestions: [] });
      return;
    }

    try {
      const response = await this.getSuggestions(inputValue)
      // const url = `${corelogic_address_suggestion_url}?q=${encodeURIComponent(inputValue)}`;
      // console.log('Fetching suggestions with URL:', url);
      // const response = await Get_Address_Suggestions(url, this.state.accessToken);
      // const response = test
      console.log('Address suggestions response:', response);
      if (response.status === 'OK') {
        // setSuggestions(response.predictions.map((suggestion) => ({
        //   title: suggestion.description,
        // })));
        this.setState({
          suggestions: response.predictions.map(suggestion => ({
            ...suggestion,
            displayText: suggestion.description || suggestion.propertyName || suggestion.address || suggestion.suggestion
          })),
        });
      } else {
        this.setState({ suggestions: [] });
      }
    } catch (error) {
      console.error('Error fetching address suggestions:', error);
      this.setState({ suggestions: [] });
    }
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  getSuggestionValue = suggestion => suggestion.displayText;

  renderSuggestion = suggestion => (
    <div>
      {suggestion.displayText}
    </div>
  );

  handleSuggestionSelected = async (event, { suggestion }) => {
    // saveAddressDetails(suggestion);
    localStorage.setItem('addressSuggestion', JSON.stringify(suggestion));
    // const { accessToken } = this.state;
    // const addressDetailsUrl = `${corelogic_address_details_url}/${suggestion.propertyId}/attributes/core?includeHistoric=false`;
    // try {
    //   const addressDetails = await Get_Address_Details(addressDetailsUrl, accessToken);
    //   console.log('Address details:', addressDetails);
    //   this.setState({ addressDetails });
    //   saveAddressDetails(addressDetails); // 保存地址详情并写入到 localStorage
    // } catch (error) {
    //   console.error('Error fetching address details:', error);
    // }
  };

  handleSearch = () => {
    const { address } = this.state;
    if (address) {
      console.log('Search for:', address);
      const addressDetails = {
        title: address,
        image: 'assets/img/feature/rent-property.webp',
        icon: 'assets/img/icons/l3.png',
        url: '/property-details',
        olderprice: '$790/mo',
        newerprice: '$350/mo',
        area: '1898 sq.',
        authorname: 'Vilma Jarvi By Redbrox',
        cat: 'cat1 cat3 cat4',
        features: [
          { icon: 'fa fa-bed', title: '2', item: 'Bed: ' },
          { icon: 'fa fa-bath', title: '1', item: 'Bath: ' },
          { icon: 'fa fa-car', title: '1', item: 'Car: ' },
          { icon: 'fa fa-arrows-alt', title: '95221 m2', item: 'Size: ' }
        ]
      };
      saveAddressDetails([addressDetails]); // 保存地址信息
      this.props.history.push('/property-report-preview'); // 使用 history.push 导航到 search-list 页面
    }
  };

  render() {
    let publicUrl = process.env.PUBLIC_URL + '/'
    let imagealt = 'image'
    let data = sectiondata.bannerv3

    const inlineStyle = {
      backgroundImage: 'url(' + publicUrl + 'assets/img/banner/首页3.jpeg)'
    }

    const { address, suggestions, addressDetails } = this.state;
    const inputProps = {
      placeholder: 'Find Property',
      value: address,
      onChange: this.onChange,
    };

    return (
      <div>
        <style>
          {styles}
        </style>
        <div className="banner-area jarallax" style={inlineStyle}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-10">
                <div className="banner-inner-wrap">
                  <div className="banner-inner w-100">
                    <h1 className="title" style={{ color: 'white' }} dangerouslySetInnerHTML={{ __html: data.title }}></h1>
                    <p className="content" dangerouslySetInnerHTML={{ __html: data.subtitle }}></p>
                    <div className="rld-banner-search">
                      <div className="rld-single-input left-icon">
                        <Autosuggest
                          suggestions={suggestions}
                          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                          getSuggestionValue={this.getSuggestionValue}
                          renderSuggestion={this.renderSuggestion}
                          onSuggestionSelected={this.handleSuggestionSelected}
                          inputProps={inputProps}
                          theme={{
                            container: 'autosuggest-container',
                            suggestionsContainer: 'autosuggest-suggestions-container',
                            suggestionsList: 'autosuggest-suggestions-list',
                            suggestion: 'autosuggest-suggestion',
                            suggestionHighlighted: 'autosuggest-suggestion--highlighted'
                          }}
                        />
                        <button className="btn-home3 realdealtop" onClick={this.handleSearch}>
                          Search Now
                        </button>
                      </div>
                    </div>
                    {/* {addressDetails && (
                      <div className="address-details">
                        <h3>Address Details</h3>
                        <pre>{JSON.stringify(addressDetails, null, 2)}</pre>
                      </div>
                    )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(BannerV3);
