import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';

class CareerSection2 extends Component {
  render() {
    let data = sectiondata.mission_career;

    const missionVissionAreaStyle = {
      paddingBottom: '90px'
    };

    const missionTitleStyle = {
      textAlign: 'center',
      marginBottom: '40px'
    };

    const gridContainerStyle = {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)', // Exactly three columns
      gap: '20px'
    };

    const singleIntroStyle = {
      padding: '20px',
      border: '1px solid #ddd',
      borderRadius: '8px',
      backgroundColor: '#fff',
      textAlign: 'left'
    };

    const textStyle = {
      fontSize: '2rem',
      color: '#edc987',
      marginBottom: '10px'
    };

    const titleStyle = {
      fontSize: '1.25rem',
      marginBottom: '10px'
    };

    const paragraphStyle = {
      marginBottom: '0'
    };

    return (
      <div style={missionVissionAreaStyle}>
        <div className="container">
          <div style={missionTitleStyle}>
            <h1>Why Join Helen Realty?</h1>
          </div>
          <div style={gridContainerStyle}>
            {data.items.map((item, i) => (
              <div key={i} style={singleIntroStyle}>
                <div style={textStyle}>{item.number}</div>
                <div className="details">
                  <h4 style={titleStyle}>
                    {/* <a href="#">{item.title}</a> */}
                    {item.title}
                  </h4>
                  <p style={paragraphStyle}>{item.content}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default CareerSection2;
