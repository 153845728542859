import React, { Component } from 'react';

class WinningTeamSection extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + '/';
    let image1 = publicUrl + '/assets/img/award1.png';
    let image2 = publicUrl + '/assets/img/award2.png';
    let image3 = publicUrl + '/assets/img/award3.png';

    const sectionStyle = {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'space-evenly',
      width: '80%',
      margin: '20px auto',
      padding: '20px 70px',
      backgroundColor: '#fff',
      color: '#2c3e50',
      textAlign: 'left', // Changed from 'center' to 'left'
    };

    const headingStyle = {
      fontSize: '3rem',
      fontFamily: 'Georgia, serif',
      color: '#2c3e50',
      marginBottom: '20px'
    };

    const subheadingStyle = {
      fontSize: '3rem',
      fontFamily: 'Georgia, serif',
      color: '#edc987',
      marginBottom: '40px'
    };

    const paragraphStyle = {
      fontSize: '1rem',
      color: '#34495e',
      marginBottom: '20px',
      textAlign: 'left' // Ensure text is left-aligned
    };

    const imageContainerStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '20px',
      marginTop: '20px'
    };

    const imageStyle = {
      maxHeight: '100px', // Adjust this to proportionally shrink the images
      height: 'auto',
      width: 'auto'
    };

    return (
      <div style={sectionStyle}>
        <div>
          <span style={headingStyle}>A winning</span>
          <span style={subheadingStyle}> team</span>
        </div>
        <p style={paragraphStyle}>
          REB's outstanding awards program has represented a benchmark for excellence for the past decade. It recognises
          the top businesses and professionals influencing and propelling the real estate industry across Australia. Our team
          proudly took home the Major Independent Group of the year award.
        </p>
        <p style={paragraphStyle}>
          Helen Realty also had a big sweep in REB's Top 50 Agents of 2022 with 11 of our sales agents featuring on the list
          - more than any other real estate agency.
        </p>
        <p style={paragraphStyle}>
          We take these formal recognitions for our brand and our people very seriously. We are driven by our sole purpose in
          helping people realise their dreams and aspirations through the property.
        </p>
        {/* <div style={imageContainerStyle}>
          <img src={image1} alt="Award 1" style={imageStyle} />
          <img src={image2} alt="Award 2" style={imageStyle} />
          <img src={image3} alt="Award 3" style={imageStyle} />
        </div> */}
      </div>
    );
  }
}

export default WinningTeamSection;
