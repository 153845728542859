import React, { Component } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

class AboutUs extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + '/'
    let image1 = publicUrl + 'assets/img/companyphoto.png';
    let image2 = publicUrl + 'assets/img/anotherphoto.png'; // 添加更多图片路径
    let imagealt = 'image'
    const career_data = {
      subtitle: "Our Story",
      title: "We a comprehensive real estate company",
      content: "HELEN REALTY is located in the heart of Adelaide's central business district. We possess a wide range of expertise and experience in various fields. Our specialties include property sales, leasing management, real estate investment, and project development in South Australia. Our company values professionalism and integrity, ensuring that every transaction is handled with care and respect. We strive for excellence and take pride in our attention to detail, providing clients with comfort, security, and peace of mind. Whether you are seeking an ideal home or an investment opportunity for appreciation, Helen Realty is dedicated to providing comprehensive services. We promise to work with integrity, transparency, and professionalism, aiming to deliver a distinctive real estate experience for you！",
    }

    const settings = {
      dots: true,
      infinite: true,
      speed: 2000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000, // 3 seconds
      pauseOnHover:false,
    };

    return (
      <div className="about-area pd-bottom-90" style={{ paddingTop: '50px' }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 mb-4 mb-lg-0" style={{alignSelf:'center'}}>
              <Slider {...settings}>
                <div>
                  <img className="shadow-img" src={image1} alt={imagealt} style={{ width: '100%' }} />
                </div>
                <div>
                  <img className="shadow-img" src={image1} alt={imagealt} style={{ width: '100%' }} />
                </div>
                {/* 可以添加更多的图片 */}
              </Slider>
            </div>
            <div className="col-lg-6 align-self-center">
              <div className="section-title pd-left mb-0">
                <h5 className="sub-title">{career_data.subtitle}</h5>
                <h2 className="title">{career_data.title}</h2>
                <p>{career_data.content}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AboutUs;
