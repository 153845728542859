import React from 'react';
import Navbar from './global-components/navbar';
import Mission from './section-components/mission';
import AboutUs from './section-components/about-us';
import ServiceTwo from './section-components/service-two';
import Footer from './global-components/footer';
// import Team from './section-components/team';
// import Client from './section-components/client';
import AboutBannerSection from './section-components/about_banner_section';



const About = () => {
    return <div>
        <Navbar />
        {/* <PageHeader headertitle="About" /> */}
        {/* Yuchen加 */}
        <AboutBannerSection />
        {/* Yuchen改 */}
        
        {/* Yuchen改 */}
        <Mission />
        {/* Yuchen改 */}
        <AboutUs />
        <ServiceTwo />
        {/* <Team /> */}
        {/* <Client /> */}
        <Footer />
    </div>
}

export default About
