import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isScrolled: false
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    if (window.scrollY > 5) {
      this.setState({ isScrolled: true });
    } else {
      this.setState({ isScrolled: false });
    }
  };

  toggleLanguage = (language) => {
    const newLang = language === 'en' ? 'zh' : 'en';
    this.props.i18n.changeLanguage(newLang);
  };

  render() {
    const { t } = this.props;
    let publicUrl = process.env.PUBLIC_URL + '/';
    console.log(publicUrl)
    let navbarStyle = {
      transition: 'background-color 0.3s, color 0.3s',
      backgroundColor: this.state.isScrolled ? 'blue' : 'transparent',
      color: '#edc987'
    };

    let linkStyle = {
      color: '#edc987'
    };

    let goldTextStyle = {
      color: 'gold'
    };

    return (
      <div>
        <div className="navbar-area" style={navbarStyle}>
          <nav className="navbar navbar-area navbar-expand-lg">
            <div className="container nav-container">
              <div className="responsive-mobile-menu">
                <button className="menu toggle-btn d-block d-lg-none" data-toggle="collapse" data-target="#realdeal_main_menu" aria-expanded="false" aria-label={t('toggleNavigation')}>
                  <span className="icon-left" />
                  <span className="icon-right" />
                </button>
              </div>
              <div className="logo readeal-top">
                <Link to="/">
                  <img
                    src={this.state.isScrolled ? publicUrl + "assets/img/logo3.png" : publicUrl + "assets/img/helenlogo1.png"}
                    alt="logo"
                    style={{ width: '130px', height: '130px', marginLeft: '-20px' }}
                  />
                </Link>
              </div>
              <div className="nav-right-part nav-right-part-mobile">
                <Link className="btn btn-yellow" to="/add-property" style={goldTextStyle}>
                  {t('addListing')} <span className="right"><i className="la la-plus" /></span>
                </Link>
              </div>
              <div className="collapse navbar-collapse" id="realdeal_main_menu">
                <ul className="navbar-nav menu-open readeal-top" style={linkStyle}>
                  <li className="menu-item-has-children">
                    <a href="#" style={linkStyle}>{t('buy')}</a>
                    <ul className="sub-menu">
                      <li><Link to="/property-for-sale" style={linkStyle}>{t('propertiesForSale')}</Link></li>
                      <li><Link to="/buy-with" style={linkStyle}>{t('buyingAdvice')}</Link></li>
                    </ul>
                  </li>
                  <li className="menu-item-has-children">
                    <a href="#" style={linkStyle}>{t('sell')}</a>
                    <ul className="sub-menu">
                      <li><Link to="/getappraisal" style={linkStyle}>{t('getSellAppraisal')}</Link></li>
                      <li><Link to="/sell-with" style={linkStyle}>{t('sellWithHelen')}</Link></li>
                    </ul>
                  </li>
                  <li className="menu-item-has-children">
                    <a href="#" style={linkStyle}>{t('rent')}</a>
                    <ul className="sub-menu">
                      <li><Link to="/property-for-rent" style={linkStyle}>{t('propertiesForRent')}</Link></li>
                      <li><Link to="/buy-with" style={linkStyle}>{t('rentingAdvice')}</Link></li>
                    </ul>
                  </li>
                  <li className="menu-item-has-children">
                    <a href="#" style={linkStyle}>{t('manage')}</a>
                    <ul className="sub-menu">
                      <li><Link to="/getappraisal" style={linkStyle}>{t('getRentalAppraisal')}</Link></li>
                      <li><Link to="/aboutus" style={linkStyle}>{t('managementService')}</Link></li>
                    </ul>
                  </li>
                  <li><Link to="project" style={linkStyle}>{t('project')}</Link></li>
                  <li className="menu-item-has-children">
                    <a style={linkStyle}>{t('aboutUs')}</a>
                    <ul className="sub-menu">
                      <li><Link to="/aboutus" style={linkStyle}>{t('helenRealtyVision')}</Link></li>
                      <li><Link to="/aboutteam" style={linkStyle}>{t('ourTeam')}</Link></li>
                      <li><Link to="/career" style={linkStyle}>{t('career')}</Link></li>
                    </ul>
                  </li>

                  <li className="menu-item-has-children">
                    <a style={linkStyle}>{t('contactUs')}</a>
                    <ul className="sub-menu">
                      <li><Link to="/contact" style={linkStyle}>{t('contactUs')}</Link></li>
                      <li><Link to="/contact" style={linkStyle}>{t('downloadForms')}</Link></li>
                    </ul>
                  </li>
                  <li>
                    <div onClick={() => this.toggleLanguage(this.props.i18n.language)} style={{cursor:"pointer"}}>
                      {this.props.i18n.language === 'en' ? t('languageToggle') : t('languageToggle')}
                    </div>
                  </li>
                </ul>
              </div>
              <div className="nav-right-part nav-right-part-desktop readeal-top">
                <Link className="btn btn-yellow" to="/getappraisal">{t('instantAppraisal')} <span className="right"></span></Link>
              </div>
            </div>
          </nav>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Navbar);
